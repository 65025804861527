import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Note } from "./note";
import { Comp } from "./comp";

// import Marquee from "react-fast-marquee";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  comp = "comp",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  activeNote: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  note: any;
  isOpen: boolean;
  popupStatuses: string;
  expanded: string;
  notes: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      isOpen: false,
      popupStatuses: popupView.none,
      expanded: "",
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(10000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();
  }

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {});
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          postCount: data.count,
          note: data.note,
          isOpen: data.count > 0 ? true : false,
        });
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {

          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;
    






    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note || this.props.activeNote)   {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
          handleClose={this.handleClosePopup}
          handleChange={this.handleChangePopup}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.help || this.props.activeHelp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }

      
      
      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Comp
            user={this.props.user}
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <div className="login">
              <ul>
                <li style={{ width: "30%" }}>
                  <input
                    className="input_login"
                    placeholder="아이디"
                    type="text"
                    id="login_id"
                    name="login_id"
                    onChange={({ target: { value } }) =>
                      this.setState({ ID: value })
                    }
                  />
                </li>
                <li style={{ width: "30%" }}>
                  <input
                    className="input_login"
                    placeholder="비밀번호"
                    type="password"
                    id="login_pass"
                    name="login_pass"
                    onChange={({ target: { value } }) =>
                      this.setState({ PW: value })
                    }
                  />
                </li>
                <li style={{ width: "20%" }}>
                  <a
                    
                    onClick={() => {
                      if (
                        this.state.ID == null ||
                        this.state.ID == "" ||
                        this.state.PW == "" ||
                        this.state.PW == null
                      ) {
                        return;
                      }
                      this.props.tryLogin(this.state.ID, this.state.PW);
                    }}
                  >
                    <span className="login_btn01">로그인</span>
                  </a>
                </li>
                <li style={{ width: "20%" }}>
                  <a
                    
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.reg });
                    }}
                  >
                    <span className="login_btn02">회원가입</span>
                  </a>
                </li>
              </ul>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="my_wrap">
            <div className="my">
              <a >
                <span style={{ float: "right" }}>
                  <span className="font01">{this.props.user.id}</span>님
                </span>
              </a>
              <span style={{ float: "right" }}>
                <img src="/images/ww_icon.png" height="16" /> 보유머니{" "}
                <span className="font05" id="lnOwnMoney">
                  {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                </span>{" "}
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        </>
      );
    };

    const RenderLogin2 = () => {
      if (authenticated === false) {
        return (
          <>
            <table
              cellSpacing="0"
              cellPadding="0"
              style={{
                width: "100%",
                border: "0",
                borderTop: "solid 1px rgba(0,0,0,0.99)",
              }}
            >
              <tr>
                <td align="center" style={{ color: "#edddd8" }}>
                  로그인 후 이용해주세요
                </td>
              </tr>
            </table>
          </>
        );
      }

      return (
        <>
          <table
            style={{
              width: "100%",
              border: "0",
              borderTop: "solid 1px rgba(0,0,0,0.99)",
            }}
            cellSpacing="0"
            cellPadding="0"
          >
            <tbody>
              <tr>
                <td align="center" colSpan={2}>
                  <span className="font01">{this.props.user.id}</span>님
                  환영합니다.
                  <a
                    onClick={() => {
                      this.props.tryLoginOut();
                    }}
                  >
                    <span className="btn1_2">로그아웃</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    };

    return (
      <>
        <div className="aside2">
          <div className="aside_wrap">
            <div className="aside_top_wrap">
              <div className="aside_top_left">
                <a >
                  <img
                    src="/images/logo.png?v=1639407278"
                    className="clsMLogoImage"
                    style={{width: '120px' }}
                  />
                </a>
              </div>
              <div data-dismiss="aside" className="aside_top_right">
                <img src="/images/m_close.png" width="40" />
              </div>
            </div>
            <div className="aside2_box1_wrap">
              <div className="aside2_box1">{RenderLogin2()}</div>
            </div>
            <div className="aside2_box2_wrap">
              <div className="aside2_box2">
                <div className="con_box00">
                  <table
                    style={{ width: "100%", border: "0", textAlign: "center" }}
                    cellSpacing="2"
                    cellPadding="0"
                  >
                    <tr>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        <a
                          
                          onClick={() => {
                            this.setState({ popupStatuses: popupView.deposit }); //입금신청
                          }}
                        >
                          <span className="aside_btn1">입금신청</span>
                        </a>
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        <a
                          
                          onClick={() => {
                            this.setState({
                              popupStatuses: popupView.withdraw,
                            }); //출금신청
                          }}
                        >
                          <span className="aside_btn1">출금신청</span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        <a
                          
                          onClick={() => {
                            this.setState({ popupStatuses: popupView.point }); //금고
                          }}
                        >
                          <span className="aside_btn1">금고</span>
                        </a>
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        <a
                          
                          onClick={() => {
                            this.setState({ popupStatuses: popupView.comp }); //금고
                          }}
                        >
                          <span className="aside_btn1">콤프</span>
                        </a>
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        <a
                          
                          onClick={() => {
                            this.setState({ popupStatuses: popupView.help }); //고객센터
                          }}
                        >
                          <span className="aside_btn1">고객센터</span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        <a
                          
                          onClick={() => {
                            this.setState({ popupStatuses: popupView.user }); //마이페이지
                          }}
                        >
                          <span className="aside_btn1">마이페이지</span>
                        </a>
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        <a
                          
                          onClick={() => {
                            this.setState({ popupStatuses: popupView.notice }); //공지사항
                          }}
                        >
                          <span className="aside_btn1">공지사항</span>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="header_wrap">
          <div className="top">
            <div className="m_logo">
              <a href="/">
                <img
                  src="/images/logo.png?v=1639407278"
                  className="clsMLogoImage"
                  style={{width: '120px' }}
                />
              </a>
            </div>
            <div className="m_menu">
              <a  id="m_menu">
                <img src="/images/m_menu.png" width="40" />
              </a>
            </div>
          </div>

          {RenderLogin()}
        </div>

        {RenderPopup()}
        {RendePopup()}
      </>
    );
  }
}
