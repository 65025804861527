import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

export class Deposit extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {balance: '0',};

    console.log(this.props.user);
  }

  handleAsk = () => {
    this.balanceService.askToAccount().then((data) => {
      if (data.status === "success") {
        
        confirmAlert({
          title: "입금",
          message: "계좌문의에 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "입금",
          message: " 로그인후 이용해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
      }
    });

    // confirmAlert({
    //   customUI: ({ onClose }) => {
    //     return (
    //       <div className='custom-ui'>
    //         <h3>계좌안내입니다.</h3>

    //         <div className='container'>

            
    //         <h3>입금절차</h3>
    //         <p>금액입력 {'>'} 충전신청 {'>'} 코인계좌연결 {'>'} 계좌입금 {'>'} 금액별 입력후 완료</p>
    //         <h3>주의사항</h3>
    //         <p>충전신청금액 입력후 코인계좌 사이트 연결시 
    //           절대 팝업창을 내리지 마시고 진행하시기 바랍니다 </p>
              
    //         <p>입금시 등록된 입금자 성함과 다를시 자동 취소됩니다</p>  
    //         <p>꼭유의하시어 회원정보에 등록하신 이름과 동일한 입금자명으로
    //           입금 바랍니다 
    //           </p> 
    //         <p>   타인명의 입금시 불이익은 본사가 책임지지 않습니다</p>
    //         </div>

    //         <button onClick={onClose}>확인</button>

    //       </div>
    //     );
    //   }
  };

  handleDoDeposit = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "입금",
        message: "입금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) < 10000) {
      confirmAlert({
        title: "입금",
        message: "최소 입금금액은 삼만원입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "입금",
        message: "입금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserDeposit(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "입금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "입금",
          message: "대기중인 입금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "입금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox">

                <ShareMenu view={popupView.deposit} handleChange={this.props.handleChange}></ShareMenu>

                <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title1">
                      입금신청{" "}
                      <span style={{ fontSize: "14px", color: "#ff00f6" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;* 보이스피싱 및 3자사기로
                        협박하더라도 협상 및 타협 절대없음 *
                      </span>
                    </div>
                    {/* <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">주의사항</div>
                        <div className="info3">
                          - 계좌거래시 입금/출금 이름이 동일해야 입출금이
                          가능하오니 정확히 입력해 주세요.
                          <br />- 수표나 타인계좌로 이름변경하여 입금시 머니몰수
                          및 아이디정지 처리되오니 타인명의로 입금해야될시
                          문의를 먼저하시기 바랍니다
                        </div>
                      </div>
                    </div> */}
                    <div className="con_box10">
                      <div className="money">
                        <ul>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            보유머니 :{" "}
                            <span className="font05" id="lnMoney">
                            {ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            금고머니 :{" "}
                            <span className="font05" id="lnMoney">
                              {ConverMoeny(this.props.user.point)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.props.updateUser();
                              }}
                            >
                              <span className="btn1_2">
                                <img src="/images/icon_re.png" height="20" />{" "}
                                새로고침
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="con_box10">
                      <table
                        style={{ width: "100%", border: "0" }}
                        cellSpacing="0"
                        cellPadding="0"
                        className="write_title_top"
                      >
                        <tbody>
                          <tr>
                            <td className="write_title">아이디</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                size={30}
                                value={this.props.user.id}
                                readOnly
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="write_title">예금주명</td>
                            <td className="write_basic">
                              <input
                                className="input1 userName"
                                size={30}
                                value={this.props.user.bankowner}
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">회원은행</td>
                            <td className="write_basic">
                              <input
                                className="input1 userBankName"
                                size={30}
                                value={this.props.user.bankname}
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">충전계좌</td>
                            <td className="write_basic">
                              <a
                                href="javascript:;"
                                id="bank_call_btn"
                                onClick={() => this.handleAsk()}
                              >
                                <span className="btn1_2">문의</span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">신청금액</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                size={30}
                                id="deposit_amount"
                                placeholder="0"
                                value={this.state.balance}
                                onFocus={() =>  this.setState({balance: ''})}

                                onChange={(e) =>
                                  this.setState({
                                    balance: e.target.value,
                                  })
                                }
                              />
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 10000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">1만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 50000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">5만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 100000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">10만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 500000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">50만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 1000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">100만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 5000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">500만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 10000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">1000만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({ balance: '0' });
                                }}
                              >
                                <span className="btn1_1">정정</span>
                              </a>
                              <br />
                              입금신청시 10,000원 단위이상 입금신청바랍니다 .
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="con_box20">
                      <div className="btn_wrap_center">
                        <ul>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={this.handleDoDeposit}
                            >
                              <span className="btn3_1">입금신청하기</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          //     <div>

          //       <div className="modal fade show" id="board_notice-modal" aria-labelledby="board_notice-modalLabel" aria-modal="true" role="dialog" style={{display:'block'}}>
          //         <div className="modal-dialog modal-xl">
          //           <div className="modal-content">
          //             <div className="modal-header">
          //             <div className="modal-title-wrapper">
          //             <div className="modal-title-container">
          //               <h5 className="title-text">입금신청</h5>
          //             </div>
          //           </div>
          //               <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {this.props.handleClose();}}>
          //                 <span aria-hidden="true"><img src="images/x-button.png"/></span>
          //               </button>
          //             </div>
          //             <div className="modal-body" style={{width:'100%',height:'500px',overflow:'auto'}}>

          //             <div className="deposit_wrapper container-fluid">
          // 	<div className="banking">
          // 		<div className="row my-1">
          // 			<div className="col-sm-3 title">
          // 				<label className="">입금금액</label>
          // 			</div>
          // 			<div className="col-sm-6 value">
          // 				<input className="banking_value deposit_custom01" type="text" id="reg_money" name="reg_money" value={this.state.balance} onChange={(e) => this.setState({balance: Number(e.target.value),})} />
          // 			</div>
          // 			<div className="col-sm-3 extra_info">최소 입금액은 30,000원 입니다.</div>
          // 		</div>
          // 		<div className="row my-1">
          // 			<div className="col-sm-3 d-none d-sm-inline-block"></div>
          // 			<div className="col-sm-6 col-12 money_value_btn_set">
          // 				<button type="button" className="btn_money_value greyblue" onClick={() => { this.setState({ balance: this.state.balance + 10000, }); }} >1만원</button>
          // 				<button type="button" className="btn_money_value greyblue" onClick={() => { this.setState({ balance: this.state.balance + 50000, }); }} >5만원</button>
          // 				<button type="button" className="btn_money_value greyblue" onClick={() => { this.setState({ balance: this.state.balance + 100000, }); }} >10만원</button>
          // 				<button type="button" className="btn_money_value greyblue" onClick={() => { this.setState({ balance: this.state.balance + 500000, }); }} >50만원</button>
          // 				<button type="button" className="btn_money_value greyblue" onClick={() => { this.setState({ balance: this.state.balance + 1000000, }); }} >100만원</button>
          // 				<button type="button" className="btn_money_value red" onClick={() => { this.setState({ balance: 0, }); }} >정정</button>
          // 			</div>
          // 			<div className="col-sm-3 d-none d-sm-inline-block"></div>
          // 		</div>
          // 		<div className="row"><hr className="deposit_custom03"/></div>
          // 		<div className="row my-1">
          // 			<div className="col-sm-3 title"><label className="">입금주</label></div>
          // 			<div className="col-sm-6 value"><input className="banking_value" type="text" value={this.props.user.bankowner} readOnly /></div>
          // 			<div className="col-sm-3"></div>
          // 		</div>
          // 		<div className="row my-1">
          // 			<div className="col-sm-3 title"><label className="">송금은행</label></div>
          // 			<div className="col-sm-6 value"><input className="banking_value" type="text" value={this.props.user.bankname} readOnly /></div>
          // 			<div className="col-sm-3"></div>
          // 		</div>
          // 		<div className="row my-1">
          // 			<div className="col-sm-3 title"><label className="">충전유의사항</label></div>
          // 			<div className="col-sm-6 ">
          // 				<div className="article">
          // 					<p>* 입금시 꼭 계좌문의를 하세요!</p>
          // 					<p></p>
          // 					<p>수표입금시 입금처리 절대 되지 않습니다.</p>
          // 					<p>최소 입금액은 3만원이상, 만원단위로 신청가능, 입금전 반드시 계좌문의 하시기바랍니다.</p>
          // 					<p>23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는 은행점검으로 인해 계좌이체가 지연될 수 있습니다.</p>
          //           <p>위 시간 이외에도 몇몇 은행은 추가적 점검시간이 따로 있으니 이점 유념하시기 바랍니다.</p>
          // 				</div>
          // 			</div>
          // 			<div className="col-sm-3"></div>
          // 		</div>
          // 		<div className="row my-1">
          // 			<div className="col-sm-3"></div>
          // 			<div className="col-sm-6 account"><span id="in_bank_txt" className="deposit_custom02"></span></div>
          // 			<div className="col-sm-3"></div>
          // 		</div>
          // 		<div className="row my-1">
          // 			<div className="col-sm-3"></div>
          // 			<div className="col-sm-6">
          // 				<div className="buttons">
          // 					<button type="button" id="in_q_btn" className="c_darkblue deposit_custom04" onClick={() => this.handleAsk()}>
          // 						<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          // 						  <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
          // 						</svg>
          // 						입금문의</button>
          // 					<button type="button" id="apply_btn" className="c_olive deposit_custom04" onClick={this.handleDoDeposit}>
          // 						<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-medical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          // 						  <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z"></path>
          // 						  <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v.634l.549-.317a.5.5 0 1 1 .5.866L9 6l.549.317a.5.5 0 1 1-.5.866L8.5 6.866V7.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L7 6l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V4.5A.5.5 0 0 1 8 4zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path>
          // 						</svg>
          // 						신청하기</button>
          // 				</div>

          // 			</div>
          // 			<div className="col-sm-3"></div>
          // 		</div>

          // 	</div>
          // </div>

          //             </div>

          //           </div>
          //         </div>
          //       </div>

          //       <div className="modal-backdrop fade show"></div>

          //     </div>
        )}
      </Popup>
    );
  }
}
